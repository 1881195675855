// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZCxx-W8MCP0Jo0uSLhIORJ0ZQ0FsjZnI",
  authDomain: "farmingseo17.firebaseapp.com",
  projectId: "farmingseo17",
  storageBucket: "farmingseo17.appspot.com",
  messagingSenderId: "356046801570",
  appId: "1:356046801570:web:126fb2c27f501d05f525fb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;