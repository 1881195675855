import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://farmingseo17-7edf3371942a.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://farmingseo17-7edf3371942a.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://farmingseo17-7edf3371942a.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://farmingseo17-7edf3371942a.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;




    const addItem = {
      subemail,



    };

    const url = `https://farmingseo17-7edf3371942a.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Subscribers Request is Sent');
      });


  }




  return (




    <>

      <footer>
        <section className="footer-3__area-common footer-3__top-cta theme-footer-bg-2 overflow-hidden">
          <div className="container container-xxl">
            <div className="row">
              <div className="col-12">
                <div className="footer-3__top-wrapper mb-75 mb-sm-65 mb-xs-50 d-flex flex-xl-row flex-column align-items-center justify-content-between theme-footer-bg-3 ">
                  {
                    logo.map(l => <div className="footer-3__logo">
                      <a href="/">
                        <img src={l.logo} alt="logo" />
                      </a>
                    </div>)

                  }
                  <div className="footer-3__top-menu">
                    <ul>
                      <li><a href="/">Home</a></li>
                      <li><a href="#about-sec">About</a></li>
                      <li><a href="#service">Services</a></li>
                      <li><a href="#pricing">Pricing</a></li>
                      <li><a href="#testimonial">Testimonials</a></li>
                      <li><a href="#contact-sec">Contact</a></li>
                    </ul>
                  </div>
                  <form onSubmit={handleSubscriber}>
                    <div className="footer-3__subscribe d-flex">
                      <input type="text" name="subemail" placeholder="Enter e-mail" />
                      <button type="submit" className="rr-btn">
                        <span className="btn-wrap">
                          <span className="text-one">Send <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.34302 13.2422L12.8283 4.75691" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.34302 4.75684H12.8283V13.2421" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          </span>
                          <span className="text-two">Send <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.34302 13.2422L12.8283 4.75691" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.34302 4.75684H12.8283V13.2421" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          </span>
                        </span>
                      </button>
                    </div></form>

                </div>
              </div>
            </div>
            <div className="row mb-minus-40">
              <div className="col-lg-12 ">
                <div className="footer-3__widget footer-3__widget-item-1">


                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="footer-3__widget footer-3__widget-item-2">
                  <div className="footer-3__widget-title">
                    <h4></h4>
                  </div>

                </div>
              </div>
              <div className="col-lg-4 ">

                

                <div className="footer-3__widget footer-3__widget-item-3">

                {
                  footerSocial.map(f=><div className="footer-3__content text-center">
                    <h2 className="section-2__title  ">About Us</h2>
                    <p className="mb-10 mb-xs-30">{f.aboutText}</p>
                   
                    <div class="footer-3__social ml-180 mt-35 mt-xs-25 text-center">
                      <a href={f.inslink}><i class="fab fa-instagram"></i></a>
                      <a href={f.fblink}><i class="fab fa-facebook-f"></i></a>
                      <a href={f.youlink}><i class="fab fa-youtube"></i></a>
                    </div>
                  </div> )
                }
                  

                </div>
              </div>


            </div>
          </div>
        </section>
      </footer>








    </>
  );
};

export default Footer;
